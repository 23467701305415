import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {LabInfo, GeneratedContent, SkideoCreateService, AppStatus} from '@vasio-nl/skideo-create-lib';
import {Subject, map, switchMap, Subscription, shareReplay} from 'rxjs';
import {environment} from 'src/environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {
  DialogData,
  DialogResult,
  PasswordDialogComponent,
} from './password-dialog/password-dialog.component';
import {
  DialogData as ConfirmDialogData,
  DialogResult as ConfirmDialogResult,
  ConfirmDialogComponent,
} from './confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-lab',
  templateUrl: './lab.component.html',
  styleUrls: ['./lab.component.scss'],
})
export class LabComponent implements OnInit, OnDestroy {
  appStatus = AppStatus.WAITING;
  labInfo: LabInfo | null = null;
  generatedContent: GeneratedContent | null = null;
  uploadForm: FormGroup | null = null;
  generatedContent$ = new Subject<GeneratedContent>();
  urlHash: string | null = null;
  environment = environment;
  downloading = false;
  clearedLabInfo$ = new Subject<LabInfo | null>();
  private subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private createService: SkideoCreateService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    const urlHash$ = this.route.url.pipe(
      map(url => url[0].path),
      shareReplay({refCount: true, bufferSize: 1}),
    );

    this.subscriptions.push(
      urlHash$.subscribe(urlHash => (this.urlHash = urlHash)),

      urlHash$
        .pipe(
          switchMap(urlHash => this.createService.getLabInfo$(urlHash, environment.instantHost)),
        )
        .subscribe(labInfo => this.checkPasswordModal(labInfo)),

      this.clearedLabInfo$.subscribe(labInfo => (this.labInfo = labInfo)),

      this.generatedContent$.subscribe(content => (this.generatedContent = content)),

      this.createService.error$.subscribe(errorObject =>
        this.snackBar.open(errorObject.message, 'Dismiss'),
      ),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private checkPasswordModal(labInfo: LabInfo): void {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    // Reusing this same for this VW campaign march 2025. Can probably be removed in a couple of months
    if(this.urlHash == 'e6cccfeae138386' && isSafari) {
      this.dialog.open<ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogResult>(
        ConfirmDialogComponent,
        {
          disableClose: true,
          width: '400px',
          data: {
            title: 'Let op!',
            description: 'Het maken van een gepersonaliseerde Transporter-video werkt helaas niet optimaal op Safari / iPhone.<br><br>Gebruik een alternatieve browser of telefoon voor de beste ervaring! 🚀'
          },
        },
      );
    }

    if (labInfo.password === undefined || labInfo.password === null) {
      this.clearedLabInfo$.next(labInfo);
      return;
    }

    const dialogRef = this.dialog.open<PasswordDialogComponent, DialogData, DialogResult>(
      PasswordDialogComponent,
      {
        disableClose: true,
        width: '400px',
        data: {
          password: labInfo.password,
        },
      },
    );

    this.subscriptions.push(
      dialogRef
        .afterClosed()
        .pipe(map(result => result?.isPasswordCorrect ?? false))
        .subscribe(result => {
          if (result === true) {
            this.clearedLabInfo$.next(labInfo);
          } else {
            this.clearedLabInfo$.next(null);
          }
        }),
    );


  }
}
